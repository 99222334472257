<!-- 基础资料 -->
<template>
  <div class="orderTest-container">
    <el-dialog :visible.sync="basisshow" title="单据维护" center>
      <div>
        <el-form ref="form" :model="form" inline label-width="120px">
          <el-form-item label="客户ID：">
            <el-input
              v-model="form.info.id"
              placeholder="客户ID"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称：">
            <el-input
              v-model="form.info.dealer_name"
              placeholder="企业名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：">
            <el-input
              v-model="form.info.boss"
              placeholder="联系人"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="电话：">
            <el-input
              v-model="form.info.mobile"
              placeholder="电话"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          stripe
          :data="form.list"
          @selection-change="handleSelectionChange"
          @row-click="handlercolumn"
        >
          <el-table-column
            width="80"
            align="center"
            type="selection"
          ></el-table-column>
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          >
            <template v-if="list.label == '数量'" #default="{ row }">
              <span style="cursor: pointer">{{ row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
        <br />
        <el-button type="primary" @click="handlerdelete">
          清空选中类型单据全部数据
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="basisshow = !basisshow">关 闭</el-button>
      </div>
    </el-dialog>
    <admin-dataorder ref="admin"></admin-dataorder>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AdminDataorder: () => import('@/baseComponents/AdminDataorder.vue'),
    },
    data() {
      return {
        basisshow: false,
        basisID: '',
        form: {
          info: {
            id: '',
            dealer_name: '',
            boss: '',
            mobile: '',
          },
          list: [],
        },
        url: {
          billlist: '/superAdmin/user-data/bill-list',
          clear: '/superAdmin/user-data/bill-clear',
        },
        colemd: [
          {
            label: '项目',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'num',
            width: '',
          },
          {
            label: '最后维护时间',
            align: 'center',
            prop: 'last_time',
            width: '',
          },
          {
            label: '维护人',
            align: 'center',
            prop: 'last_user',
            width: '',
          },
        ],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlercolumn(row) {
        console.log(row)
        this.$refs.admin.admindata = true
        var rows = {
          bill_id: row.bill_id,
          id: this.basisID,
          name: row.name,
        }
        this.$refs.admin.handlerdata(rows)
      },
      headlerData(id) {
        this.basisID = id
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.billlist, { id: this.basisID })
          .then((res) => {
            console.log(res)
            this.form = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSelectionChange(e) {
        console.log(e, '选的')
        this.bill_id = e
          .map((list) => {
            return list.bill_id
          })
          .join(',')
      },
      handlerdelete() {
        postAction(this.url.clear, {
          id: this.basisID,
          bill_ids: this.bill_id,
        })
          .then((res) => {
            console.log(res, 'res')
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
